.center-text { 
  text-align:center;
  justify-content:center;
  align-items: center;
  width:90%;
  align-self:center;

}

.center-left { 
  padding-left:20;
  padding-right:20;

}